@font-face {
  font-family: "CoFo Sans";
  font-style: normal;
  font-weight: 400;
  src: url("assets/Fonts/CoFo_Sans-Regular.woff") format("woff");
}

@font-face {
  font-family: "CoFo Sans";
  font-style: normal;
  font-weight: 500;
  src: url("assets/Fonts/CoFo_Sans-Medium.woff") format("woff");
}

@font-face {
  font-family: "CoFo Sans";
  font-style: normal;
  font-weight: 700;
  src: url("assets/Fonts/CoFo_Sans-Bold.woff") format("woff");
}

@font-face {
  font-family: "CoFo Sans";
  font-style: normal;
  font-weight: 900;
  src: url("assets/Fonts/CoFo_Sans-Black.woff") format("woff");
}

@font-face {
  font-family: "CoFo Sans";
  font-style: italic;
  font-weight: normal;
  src: url("assets/Fonts/CoFo_Sans-Regular_Italic.woff") format("woff");
}

@font-face {
  font-family: "CoFo Sans";
  font-style: italic;
  font-weight: 500;
  src: url("assets/Fonts/CoFo_Sans-Medium_Italic.woff") format("woff");
}

@font-face {
  font-family: "CoFo Sans";
  font-style: italic;
  font-weight: 700;
  src: url("assets/Fonts/CoFo_Sans-Bold_Italic.woff") format("woff");
}

@font-face {
  font-family: "CoFo Sans";
  font-style: italic;
  font-weight: 900;
  src: url("assets/Fonts/CoFo_Sans-Black_Italic.woff") format("woff");
}

.markdown ul li {
  list-style-type: "\2713";
  padding-left: 8px;
  text-align: left;
  margin-left: 1em;
  margin-bottom: 4px;
}
